let Adjs = [
    'great',
    'greatest',
    'american',
    'first',
    'young',
    'gifted',
    'best',
    'famous',
    'romantic',
    'good',
    'fine',
    'successful',
    'accomplished',
    'german',
    'popular',
    'talented',
    'pure',
    'outstanding',
    'brilliant',
    'true',
    'supreme',
    'professional',
    'prolific',
    'finest',
    'dramatic',
    'important',
    'greek',
    'original',
    'chief',
    'former',
    'natural',
    'modern',
    'contemporary',
    'english',
    'skilled',
    'noted',
    'french',
    'celebrated',
    'century',
    'musical',
    'superb',
    'known',
    'minor',
    'foremost',
    'famed',
    'witty',
    'distinguished',
    'sensitive',
    'finer',
    'principal',
    'excellent',
    'genuine',
    'anonymous',
    'mere',
    'delicate',
    'commercial',
    'elizabethan',
    'occasional',
    'passionate',
    'impetuous',
    'fuliginous',
    'unknown',
    'promising',
    'imaginative',
    'winning',
    'purest',
    'latin',
    'sole',
    'renowned',
    'time',
    'thoughtful',
    'subtle',
    'medieval',
    'tenderest',
    'spanish',
    'clever',
    'spontaneous',
    'aspiring',
    'favorite',
    'roman',
    'experienced',
    'good',
    'chief',
    'professional',
    'human',
    'senior',
    'skilled',
    'original',
    'average',
    'individual',
    'expert',
    'old',
    'competent',
    'single',
    'junior',
    'level',
    'typical',
    'young',
    'non',
    'time',
    'better',
    'advanced',
    'clever',
    'inexperienced',
    'serious',
    'best',
    'language',
    'former',
    'successful',
    'windows',
    'external',
    'oriented',
    'proficient',
    'freelance',
    'scientific',
    'automatic',
    'fortran',
    'talented',
    'lead',
    'universal',
    'trained',
    'seasoned',
    'brilliant',
    'sophisticated',
    'excellent',
    'effective',
    'ordinary',
    'knowledgeable',
    'independent',
    'responsible',
    'electronic',
    'wise',
    'accomplished',
    'linear',
    'held',
    'careful',
    'prom',
    'instructional',
    'unwary',
    'casual',
    'solvent',
    'creative',
    'malicious',
    'smart',
    'parallel',
    'based',
    'capable',
    'top',
    'unethical',
    'manual',
    'russian',
    'lazy',
    'productive',
    'conventional',
    'amateur',
    'intermediate',
    'html',
    'digital',
    'functional',
    'multimedia',
    'great',
    'professional',
    'young',
    'good',
    'accomplished',
    'talented',
    'excellent',
    'fine',
    'famous',
    'trained',
    'amateur',
    'gifted',
    'chief',
    'skilled',
    'german',
    'american',
    'greatest',
    'poor',
    'true',
    'italian',
    'celebrated',
    'blind',
    'english',
    'classical',
    'known',
    'distinguished',
    'serious',
    'french',
    'better',
    'eminent',
    'brilliant',
    'itinerant',
    'popular',
    'creative',
    'practical',
    'skilful',
    'competent',
    'successful',
    'wonderful',
    'thorough',
    'fellow',
    'time',
    'outstanding',
    'expert',
    'prominent',
    'sensitive',
    'favorite',
    'experienced',
    'non',
    'admirable',
    'able',
    'aspiring',
    'rate',
    'versatile',
    'learned',
    'enthusiastic',
    'noted',
    'renowned',
    'clever',
    'jazz',
    'fair',
    'orchestral',
    'russian',
    'born',
    'remarkable',
    'consummate',
    'african',
    'keen',
    'superb',
    'contemporary',
    'skillful',
    'unknown',
];

export default Adjs;
