<script>
    let home = 'https://mdguerrero.com';
    // let twir = 'https://mdguerrero.com/twir';
    let blog = 'https://mdguerrero.com/blog';
    let about = 'https://mdguerrero.com/about';
</script>

<div class="sitehead">
    <h1><a href={home}>mdguerrero.com</a></h1>
    <ul class="routes">
        <li><a href={about}>about</a></li>
        <li><a href={blog}>blog</a></li>
        <!-- <li><a href={twir}>this week in rust</a></li> -->
    </ul>
</div>

<style>
    a {
        text-decoration: none;
        padding: 5px;
        color: #233342;
        border: 7px transparent #faebd7;
    }
    a:hover {
        background-color: #233342;
        color: #ceb037;
        border-radius: 7px;
        padding: 5px;
        border: 7px transparent #faebd7;
        cursor: pointer;
    }
    .sitehead {
        width: 90%;
        margin: 10px auto;
        text-align: center;
        border: 3px solid #ceb037;
        border-radius: 7px;
        background-image: url('https://mdguerrero.com/banner.jpg');
    }
    .routes {
        margin-left: -40px;
        list-style: none;
        display: flex;
        justify-content: center;
    }
    .routes > li {
        margin: 0 10px 0 10px;
    }
    @media (max-width: 500px) {
        h1 a {
            font-size: 16pt;
        }
    }
</style>
